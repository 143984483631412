import React from 'react';

import {
  Box,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import placeHolderImage from 'assets/img/placeholder.jpg';
import ButtonSx from 'components/button/ButtonSx';

import FilterTree from './FilterTree';

const SelectMaterial = () => {
  const navigate = useNavigate();
  const { materialType } = useParams();
  return (
    <Stack alignItems={'center'} sx={{ p: 3 }}>
      <Stack gap={3} sx={{ flexDirection: { xs: 'column', lg: 'row' } }}>
        <Stack gap={2}>
          <Typography sx={{ fontWeight: 600, fontSize: 36 }}>
            FILTERS
          </Typography>
          <Box sx={{ bgcolor: '#EEEEEE', pr: 3, py: 1 }}>
            <FilterTree />
          </Box>
        </Stack>
        <Stack gap={2}>
          <Typography
            sx={{ textAlign: 'center', fontWeight: 600, fontSize: 36 }}
          >
            {materialType}
          </Typography>
          <Divider />
          <Typography>Showing 100 colours</Typography>
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <TextField
              placeholder="Name or Code"
              id="outlined-basic"
              variant="outlined"
              sx={{
                '& .MuiInputBase-root': {
                  height: 50,
                  backgroundColor: 'white',
                },
              }}
            />
            <ButtonSx
              buttonText="Search"
              onButtonPress={() => {}}
              isLoading={false}
              sx={{
                borderRadius: '5px',
                height: 50,
              }}
            />
          </Stack>
          <Grid container sx={{ maxWidth: '900px' }} spacing={2}>
            {[
              { id: 'high-gloss', label: 'High Gloss', count: 13 },
              { id: 'perfect-matt', label: 'Perfect Matt', count: 20 },
            ]?.map((item) => (
              <Grid key={item?.id} item xs={4}>
                <img
                  onClick={() =>
                    navigate(`/select-material/${materialType}/${item?.id}`)
                  }
                  src={placeHolderImage}
                  alt="logo"
                  width={'100%'}
                  style={{
                    height: 'auto',
                    objectFit: 'contain',
                    border: '1px solid #EEEEEE',
                    cursor: 'pointer',
                  }}
                />
                <Typography sx={{ textAlign: 'end', mr: 1 }}>
                  {item?.label}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SelectMaterial;
