import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const StickyFooter = ({ children, sx }) => {
  return (
    <Box
      display={'flex'}
      justifyContent={'flex-end'}
      alignItems={'center'}
      sx={[
        {
          backgroundColor: '#E5E5E5',
          position: 'sticky',
          bottom: 0,
          flexDirection: { xs: 'column', sm: 'row' },
          ml: { xs: 0, sm: -4 },
          mt: 10,
          px: 6,
          py: 1.5,
        },
        sx,
      ]}
      width={'100vw'}
    >
      {children}
    </Box>
  );
};

StickyFooter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object,
  ]),
  sx: PropTypes.any,
};

export default StickyFooter;
