import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import ButtonSx from 'components/button/ButtonSx';
import ProjectPopup from 'components/project-popup/ProjectPopup';
import StickyFooter from 'components/sticky-footer/StickyFooter';
import useAuth from 'context/AuthContext';
import OrderItemDialog from 'pages/orders/components/OrderItemDialog';
import { datagridSx } from 'pages/orders/Orders';
import {
  useCreateDuplicateProjectsMutation,
  useGetAllProjectsOfUserQuery,
  // useGetUserProjectsQuery,
  // useGetOrdersQuery,
} from 'store/apis/order';
import {
  setSelectedProject,
  setSelectedProjectForOptions,
} from 'store/slices/projectSlice';
import { pxToRem } from 'theme-loader/theme/typography';
import { useNotifyToast } from 'utils/useNotifyToast';

const tabOptions = [
  { label: 'Drafts', value: 'drafts', component: () => {} },
  { label: 'Invoiced', value: 'invoiced', component: () => {} },
];

const validationSchema = yup.object({
  projects: yup.array().of(
    yup.object({
      name: yup.string().required('Project name cannot be empty'),
    })
  ),
});
const MyProjects = () => {
  const { path } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    navigate(`/my-projects/${newValue}`);
  };
  const [filter, setFilter] = React.useState({
    sortBy: 'createdAt',
    orderBy: 'desc',
    page: 1,
    limit: 10,
  });
  const [sortModel, setSortModel] = useState({});
  const [openDialog, setOpenDialog] = React.useState(false);

  const [selectedItem, setSelectedItem] = React.useState(null);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [isProjectPopupOpen, setProjectPopupOpen] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  const {
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      projectsFields: [],
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const {
    data: fetchMyProjects,
    isLoading,
    isFetching,
  } = useGetAllProjectsOfUserQuery(
    { projectType: path, ...filter },
    { skip: !auth?.authenticated }
  );

  const { data: allProjects } = useGetAllProjectsOfUserQuery(
    {},
    { skip: !auth?.authenticated }
  );

  const [
    createDuplicateProjects,
    {
      isLoading: isDuplicatePLoading,
      isSuccess: isDuplicatePSuccess,
      isError: isDuplicateProjectError,
      error: duplicatePError,
    },
  ] = useCreateDuplicateProjectsMutation();

  const handlePageChange = (newPage) => {
    setFilter({ ...filter, page: newPage + 1 });
  };

  const handlePageSizeChange = (newPageSize) => {
    setFilter({ ...filter, limit: newPageSize });
  };
  const notifyToast = useNotifyToast();

  useEffect(() => {
    if (!isDuplicatePLoading && isDuplicatePSuccess) {
      setProjectPopupOpen(false);
      notifyToast(
        'Project duplicated successfully!',
        'projectDuplicateSuccess',
        'success'
      );
    } else if (isDuplicateProjectError) {
      notifyToast(duplicatePError?.data?.errorMessage, 'updateError', 'error');
    }
  }, [
    duplicatePError?.data?.errorMessage,
    isDuplicatePLoading,
    isDuplicatePSuccess,
    isDuplicateProjectError,
    notifyToast,
  ]);

  useEffect(() => {
    if (sortModel && sortModel?.length) {
      const { field, sort } = sortModel[0];
      setFilter((prevState) => ({
        ...prevState,
        sortBy: field,
        orderBy: sort,
      }));
    }
  }, [sortModel]);

  const mergedObject = {};
  selectedRows?.forEach((project) => {
    let name = project?.name || project?.projectName;
    if (!mergedObject[name]) {
      mergedObject[name] = 1;
    }
    let projectId = path === 'invoiced' ? project?.projectId : project?.id;
    allProjects?.forEach((item) => {
      if (item?.duplicatedFrom === projectId) {
        mergedObject[name] = mergedObject[name] + 1;
      }
    });
  });

  const handleClose = () => {
    reset();
    setSelectionModel([]);
    setSelectedRows([]);
    setProjectPopupOpen(false);
  };

  const columns = [
    { field: 'id', headerName: 'ID', sortable: false, hide: true },
    {
      field: 'createdAt',
      headerName: 'Date',
      sortable: true,
      minWidth: 165,
      flex: 1,
      renderCell: (params) => {
        return moment(params?.row?.createdAt).format('MMMM DD, YYYY');
      },
    },
    {
      field: 'projectName',
      headerName: 'Projects',
      sortable: true,
      minWidth: 155,
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography
            noWrap
            sx={{
              maxWidth: '150px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {params?.row?.name || params?.row?.projectName}
          </Typography>
        );
      },
    },
    path !== 'drafts' && {
      field: 'purchaseOrderNo',
      headerName: 'Order #',
      sortable: true,
      minWidth: 145,
      flex: 1,
    },
    {
      field: 'Action',
      headerName: 'Action',
      sortable: false,
      minWidth: 140,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            container
            direction="row"
            justifyContent="space-around"
            spacing={2}
            alignItems="center"
          >
            <Button
              sx={{
                fontSize: '14px',
              }}
              onClick={() => {
                if (path === 'drafts') {
                  dispatch(setSelectedProject(params?.row));
                  dispatch(setSelectedProjectForOptions(params?.row));
                  navigate('/projects/cabinets');
                } else {
                  setOpenDialog(true);
                  setSelectedItem({
                    row: params?.row,
                    id: params?.row?.orderInfoId,
                  });
                }
              }}
            >
              View Details
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={10} xl={9} px={{ sm: 0 }}>
          <Box display={'flex'} px={2} justifyContent={'space-between'}>
            <Typography
              flexWrap={'wrap'}
              sx={{
                fontSize: pxToRem(30),
                fontWeight: 700,
                maxWidth: '85%',
                wordBreak: 'break-word',
              }}
            >
              My Projects
            </Typography>
          </Box>
          <Box px={2}>
            <Tabs
              value={path}
              onChange={handleChange}
              indicatorColor="white"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
              sx={{
                backgroundColor: '#E5E5E5',
                mt: 2,
              }}
            >
              {tabOptions?.map((tab) => {
                return (
                  <Tab key={tab.value} value={tab.value} label={tab.label} />
                );
              })}
            </Tabs>
          </Box>
          <Box sx={{ paddingTop: 2 }}>
            <Box sx={{ height: '100%', mx: 2 }}>
              <Paper elevation={0} sx={{ mb: 8 }}>
                <DataGrid
                  onSelectionModelChange={(selectedIds) => {
                    setSelectionModel(selectedIds);
                    const selectedRowData = fetchMyProjects.filter((row) =>
                      selectedIds.includes(row.id)
                    );

                    setSelectedRows(selectedRowData);
                  }}
                  selectionModel={selectionModel}
                  checkboxSelection
                  disableRowSelectionOnClick
                  onSortModelChange={(model) => {
                    setSortModel(model);
                  }}
                  sortingMode="server"
                  sortingOrder={['desc', 'asc']}
                  autoHeight
                  disableColumnMenu
                  sx={datagridSx}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'createdAt', sort: 'desc' }],
                    },
                    pagination: {
                      page: 1,
                      pageSize: 10,
                    },
                  }}
                  rowCount={fetchMyProjects?.[0]?.total_count || 0}
                  rowsPerPageOptions={[10, 20, 30, 40, 50]}
                  pagination
                  page={filter.page - 1}
                  pageSize={filter.limit}
                  paginationMode="server"
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  rows={fetchMyProjects || []}
                  columns={columns}
                  loading={isLoading || isFetching}
                  components={{
                    NoRowsOverlay: () => (
                      <Stack
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        No Orders
                      </Stack>
                    ),
                  }}
                />
              </Paper>
              {openDialog && (
                <OrderItemDialog
                  open={openDialog}
                  handleClose={() => setOpenDialog(false)}
                  selectedItem={selectedItem}
                  projectType={path}
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      {selectedRows?.length !== 0 && (
        <StickyFooter>
          <Stack
            sx={{
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'end',
            }}
            gap={2}
            width="100%"
          >
            <Stack flexDirection={'row'}>
              <ButtonSx
                sx={{
                  textTransform: 'uppercase',
                }}
                buttonText={`Duplicate Project${
                  selectedRows?.length > 1 ? 's' : ''
                }`}
                onButtonPress={() => {
                  const selectedRowData = selectedRows.map((item) => {
                    let name = item?.name || item?.projectName;
                    return {
                      ...item,
                      updatedName: `${name}-copy-${mergedObject[name]}`,
                    };
                  });
                  setSelectedRows(selectedRowData);
                  setProjectPopupOpen(true);
                }}
              />
            </Stack>
          </Stack>
        </StickyFooter>
      )}
      {isProjectPopupOpen && (
        <ProjectPopup
          desc={
            'Modify the projects name accordingly to group multiple cabinets together.'
          }
          isOpen={isProjectPopupOpen}
          onClose={handleClose}
          title={'Duplicate projects'}
          submitButtonText="Duplicate"
          isConfirmLoading={isDuplicatePLoading}
          isConfirmDisabled={!!Object.keys(errors).length}
          childComponent={
            <>
              <Divider sx={{ mb: 1 }} />
              <form>
                <Stack
                  gap={2}
                  sx={{
                    p: 1,
                    overflowY: 'auto',
                    height: 'auto',
                    maxHeight: '300px',
                  }}
                >
                  {selectedRows?.map((project, index) => {
                    return (
                      <Stack
                        key={project?.id}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        gap={2}
                      >
                        <Controller
                          key={project?.id}
                          control={control}
                          name={`projects.${index}.name`}
                          defaultValue={project?.updatedName}
                          render={({ field }) => {
                            const { onChange } = field;
                            return (
                              <TextField
                                {...field}
                                onChange={(e) => {
                                  const newValue = e.target.value;

                                  setSelectedRows((prev) =>
                                    prev.map((item) =>
                                      item?.id === project?.id
                                        ? { ...item, updatedName: newValue }
                                        : item
                                    )
                                  );
                                  onChange(e);
                                }}
                                key={project?.id}
                                sx={{ width: '100%' }}
                                error={!!errors.projects?.[index]?.name}
                                helperText={
                                  errors.projects?.[index]?.name?.message
                                }
                                inputProps={{ maxLength: 70 }}
                              />
                            );
                          }}
                        />
                      </Stack>
                    );
                  })}
                </Stack>
              </form>
              <Typography sx={{ p: 1 }}>
                Note: Make sure to verify the name of the duplicated projects
              </Typography>
            </>
          }
          onConfirm={() => {
            let body;
            body = {
              data: [
                ...selectedRows.map((item) => ({
                  duplicatedFrom:
                    path === 'invoiced' ? item?.projectId : item?.id,
                  name: item.updatedName,
                  orderInfoId: item.orderInfoId,
                  orderProjectsId: item?.id,
                })),
              ],
            };
            createDuplicateProjects(body);
            handleClose();
          }}
        />
      )}
    </>
  );
};

export default MyProjects;
