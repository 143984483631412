import { useCallback } from 'react';

import { toast } from 'react-toastify';

import { useInternetState } from './useInternetState';

export const useNotifyToast = () => {
  const isOnline = useInternetState();

  const notifyToast = useCallback(
    (message, toastId, type, isAuth) => {
      toast.info(
        isOnline
          ? message || 'Something went wrong!'
          : 'No internet connection',
        {
          toastId: toastId,
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: isOnline ? type : 'error',
          style: {
            marginTop: isAuth ? 0 : 80,
          },
        }
      );
    },
    [isOnline]
  );

  return notifyToast;
};
