import { useState, useEffect } from 'react';

export const useInternetState = () => {
  const [isOnline, setOnline] = useState(true);

  const onlineListener = () => {
    setOnline(true);
  };
  const offlineListener = () => {
    setOnline(false);
  };
  useEffect(() => {
    setOnline(navigator.onLine);
    window.addEventListener('online', onlineListener);
    window.addEventListener('offline', offlineListener);
    return () => {
      window.removeEventListener('online', onlineListener);
      window.removeEventListener('offline', offlineListener);
    };
  }, []);

  return isOnline;
};
