import React, { useEffect, useRef, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import './OrderIemDialog.css';

import useAuth from 'context/AuthContext';
import { useGetCustomerQuery } from 'store/apis/customer';
import {
  useGetAllMaterialsQuery,
  useGetOrderItemsQuery,
} from 'store/apis/order';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index ? (
        <Box sx={{ py: 1 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      ) : null}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes?.node,
  index: PropTypes?.number?.isRequired,
  value: PropTypes?.number?.isRequired,
};

const OrderItemDialog = (props) => {
  const { open, selectedItem, handleClose, projectType } = props;
  const [value, setValue] = React.useState(0);
  const { row, id } = selectedItem;

  const cabinetInfo = (item) => {
    return [
      {
        title: 'Catalog',
        value: item?.catalog,
      },
      {
        title: 'Code',
        value: item?.code,
      },
      {
        title: 'Short Name',
        value: item?.shortName,
      },
      {
        title: 'Long Name',
        value: item?.longName,
      },
      {
        title: 'Dimension (w x h x d)',
        value: item?.width + ' x ' + item?.height + ' x ' + item?.depth,
      },
      {
        title: 'Type',
        value: item?.type,
      },
      {
        title: 'Quantity',
        value: item?.quantity,
      },
      // {
      //   title: 'Hinging',
      //   value: item?.hinging,
      // },
    ];
  };

  const projectInfo = (item) => {
    // const finishedEndsData = materials?.finishedEndsOption?.find(
    //   (data) => data?.value === item?.finishedEnds
    // );

    return [
      {
        title: 'Number',
        value: item?.project?.projectNo,
      },
      {
        title: 'Name',
        value: item?.project?.projectName,
      },

      {
        title: 'Case Material',
        value: item?.project?.caseMaterialName,
      },
      {
        title: 'Door Material',
        value: item?.project?.doorMaterialName,
      },
      {
        title: 'Drawer Box',
        value: item?.project?.drawerBoxName,
      },
      {
        title: 'Grain Material',
        value: item?.project?.grainName,
      },
      {
        title: 'Door assembly',
        value: item?.project?.assemblyName,
      },
      {
        title: 'Case Edgebanding',
        value:
          item?.project?.caseBandLF === 'matchDoor'
            ? 'Match Door'
            : 'Match Case',
      },
      {
        title: 'Notes',
        value: item?.project?.projectSpecificNotes,
      },
      {
        title: 'Special Instructions',
        value: item?.project?.projectSpecialInstructions,
      },
    ];
  };

  const settingsInfo = (item) => {
    const leftRightEndOptions = (val) => {
      return materials?.leftRightEndOptions?.find(
        (element) => element?.value === val
      );
    };
    const commonSettings = [
      {
        title: 'Grain Pattern',
        value: item?.grainName,
      },
      // {
      //   title: 'Finished Ends',
      //   value: finishedEndsData?.option,
      // },
      {
        title: 'Left End',
        value: leftRightEndOptions(item?.leftEnd)?.option,
      },
      {
        title: 'Right End',
        value: leftRightEndOptions(item?.rightEnd)?.option,
      },
    ];

    if (item?.toekick) {
      commonSettings.push({
        title: 'Toekick Height',
        value: item?.toekickHeight,
      });
      commonSettings.push({
        title: 'Toekick Depth',
        value: item?.toekickDepth,
      });
    }
    if (item?.type === 'BASE' || item?.type === 'WALL') {
      commonSettings.push({
        title: 'Hang Rail Notches',
        value: item?.hangRailNotches,
      });
    }
    const nonWallValues = [
      {
        title: 'Adjustable Feet Boring',
        value: item?.legBore,
      },
      {
        title: 'Adjustable Feet Placement',
        value: item?.legs,
      },
    ];
    return [
      ...commonSettings,
      // {
      //   title: 'Applied End Scribe',
      //   value: item?.appliedEndScribe,
      // },
      ...((item?.type !== 'WALL' && nonWallValues) || []),
    ];
  };

  const revealsInfo = (item) => {
    return [
      {
        title: 'Left Reveal',
        value: item?.leftReveal,
      },
      {
        title: 'Right Reveal',
        value: item?.rightReveal,
      },
      {
        title: 'Top Reveal',
        value: item?.topReveal,
      },
      {
        title: 'Bottom Reveal',
        value: item?.bottomReveal,
      },
    ];
  };

  // const cornerExtensionsInfo = (item) => {
  //   return [
  //     {
  //       title: 'Top Left Corner',
  //       value: item?.topLeftCorner,
  //     },
  //     {
  //       title: 'Top Right Corner',
  //       value: item?.topRightCorner,
  //     },
  //     {
  //       title: 'Bottom Left Corner',
  //       value: item?.bottomLeftCorner,
  //     },
  //     {
  //       title: 'Bottom Right Corner',
  //       value: item?.bottomRightCorner,
  //     },
  //   ];
  // };

  const adjustmentInfo = (item) => {
    const adjustments = item?.adjustments;

    const adjustmentParametersItem = materials?.cabinets?.find(
      (data) => data?.code === item?.code
    );
    const newArr = [];

    const adj = {};
    if (adjustments?.adjustmentShelves) {
      adj.adjustmentShelves = 'Adjustment Shelves';
    }

    adjustmentParametersItem?.adjustmentParameters?.map(
      (item) => (adj[item?.id] = item?.desc)
    );

    adjustments &&
      Object?.entries(adjustments)?.forEach(([key, value]) => {
        if (value !== null) {
          newArr?.push({
            title: adj[key],
            value: value,
          });
        }
      });
    return newArr;
  };

  const notesInfo = (item) => {
    return [
      {
        title: 'Special Instructions',
        value: item?.specialInstructions || '-',
      },
      {
        title: 'Note',
        value: item?.note || '-',
      },
    ];
  };

  const costInfo = (item) => {
    return [
      {
        title: 'Case Material Cost',
        value: Number(item?.caseMaterialCost)?.toFixed(2),
      },
      {
        title: 'Finished End Material Cost',
        value: Number(item?.finishedEndMaterialCost)?.toFixed(2),
      },
      {
        title: 'Hardware Total Cost',
        value: Number(item?.hardwareTotalCost)?.toFixed(2),
      },
      {
        title: 'Drawer Total Cost',
        value: Number(item?.drawerTotalCost)?.toFixed(2),
      },
      {
        title: 'CNC Per Sheet Case Cost',
        value: Number(item?.cncPerSheetCaseCost)?.toFixed(2),
      },
      {
        title: 'CNC Per Sheet Door Cost',
        value: Number(item?.cncPerSheetDoorCost)?.toFixed(2),
      },
      {
        title: 'Lf Banding Case Cost',
        value: Number(item?.lfBandingCaseCost)?.toFixed(2),
      },
      {
        title: 'Lf Banding Door Cost',
        value: Number(item?.lfBandingDoorCost)?.toFixed(2),
      },
      {
        title: 'Processing Hardware Cost',
        value: Number(item?.processingHardwareCost)?.toFixed(2),
      },
      {
        title: 'Processing Total Cost',
        value: Number(item?.processingTotalCost)?.toFixed(2),
      },
      {
        title: 'Assembly And Handling Cost',
        value: Number(item?.assemblyAndHandlingCost)?.toFixed(2),
      },
      {
        title: 'Cost Each',
        value: Number(item?.costEach)?.toFixed(2),
      },
      {
        title: 'Sell Each',
        value: Number(item?.sellEach)?.toFixed(2),
      },
      {
        title: 'Total',
        value: Number(item?.itemTotal)?.toFixed(2),
      },
    ];
  };
  const auth = useAuth();

  const { data: user } = useGetCustomerQuery(
    {},
    { skip: !auth?.authenticated }
  );

  const [itemDetailObj, setItemDetailObj] = useState({
    'Project Info': projectInfo,
    'Cabinet Info': cabinetInfo,
    'Settings Info': settingsInfo,
    'Reveals Info': revealsInfo,
    // 'Corner Extensions Info': cornerExtensionsInfo,
    'Adjustment Info': adjustmentInfo,
    'Notes Info': notesInfo,
  });
  const ref = useRef();

  useEffect(() => {
    if (user?.role === 'ADMIN') {
      setItemDetailObj((prevState) => ({
        ...prevState,
        'Cost Info': costInfo,
      }));
    }
  }, [setItemDetailObj, user?.role]);

  const { data, isLoading } = useGetOrderItemsQuery(id);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    ref?.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const { data: materials } = useGetAllMaterialsQuery();

  const customerInfo = [
    {
      title: 'Name',
      value: row?.firstName + ' ' + row?.lastName,
    },
    {
      title: 'Email',
      value: row?.email,
    },
    {
      title: 'Phone',
      value: row?.phone,
    },
  ];

  const deliveryInfo = [
    {
      title: 'Contact Name',
      value: row?.contactName,
    },
    {
      title: 'Address',
      value: row?.address,
    },
    {
      title: 'Address2',
      value: row?.address2,
    },
    {
      title: 'Email',
      value: row?.deliveryEmail,
    },
    {
      title: 'Phone',
      value: row?.deliveryPhone,
    },
    {
      title: 'City',
      value: row?.city,
    },
    {
      title: 'State',
      value: row?.state,
    },
    {
      title: 'Zip',
      value: row?.zipCode,
    },
    {
      title: 'Shipping Method',
      value: row?.shippingMethod,
    },
    {
      title: 'Shipping Note',
      value: row?.shippingNote,
    },
  ];

  const billingInfo = [
    {
      title: 'Name',
      value: row?.billingFirstName + '  ' + row?.billingLastName,
    },
    {
      title: 'Address',
      value: row?.billingAddress,
    },
    {
      title: 'Address2',
      value: row?.billingAddress2,
    },
    {
      title: 'Phone',
      value: row?.billingPhone,
    },
    {
      title: 'Email',
      value: row?.billingEmail,
    },
    {
      title: 'City',
      value: row?.billingCity,
    },
    {
      title: 'State',
      value: row?.billingState,
    },
    {
      title: 'Zip',
      value: row?.billingZip,
    },
  ];

  const orderInfo = [
    {
      title: 'Total Item',
      value: row?.totalItem,
    },
    {
      title: 'Promo Code',
      value: row?.appliedPromoCode,
    },
    {
      title: 'Discount Amount',
      value: row?.discountAmount,
    },
    {
      title: 'Tax',
      value: row?.tax,
    },
    {
      title: 'Order Status',
      value: row?.orderStatus,
    },
    {
      title: 'Note',
      value: row?.note,
    },
    {
      title: 'Total Cost',
      value: row?.totalCost,
    },
    {
      title: projectType ? 'Cabinets' : 'Projects',
      value: projectType
        ? data?.orderItems?.map((item) => item?.code).join(', ') || ''
        : row?.projects?.map((item) => item.projectName).join(', ') || '-',
    },
  ];

  useEffect(() => {
    window?.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '800px !important',
          overflowY: 'unset',
        },
      }}
      disableScrollLock
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>
            <b>Order Items</b>
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: 'background?.paper',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            sx={{
              [`& ?.${tabsClasses?.scrollButtons}`]: {
                '&?.Mui-disabled': { opacity: 0.3 },
              },
            }}
          >
            <Tab label="Info" />

            {!isLoading
              ? data?.orderItems?.map((item, index) => (
                  <Tab key={item + index} label={`Item ${index + 1}`} />
                ))
              : null}
          </Tabs>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ height: '400px' }} ref={ref}>
        {isLoading ? (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : null}
        {!isLoading ? (
          <>
            <TabPanel value={value} index={0}>
              <Typography variant="display6" paragraph>
                Customer Info :
              </Typography>
              <Grid container spacing={2}>
                {customerInfo?.map((item, index) => (
                  <React.Fragment key={index}>
                    {item?.value ? (
                      <Grid item xs={12} sm={6} md={4} className="ellipsis">
                        <Typography>{item?.title}</Typography>
                        <Typography variant="subtitle">
                          {item?.value}
                        </Typography>
                      </Grid>
                    ) : null}
                  </React.Fragment>
                ))}
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Typography variant="display6" paragraph>
                Order Info:
              </Typography>
              <Grid container spacing={2}>
                {orderInfo?.map((item, index) => (
                  <React.Fragment key={index}>
                    {item?.value ? (
                      <Grid item xs={12} sm={6} md={4} className="ellipsis">
                        <Typography>{item?.title}</Typography>
                        <Typography
                          variant="subtitle"
                          sx={{
                            textWrap: 'wrap',
                          }}
                        >
                          {item?.value}
                        </Typography>
                      </Grid>
                    ) : null}
                  </React.Fragment>
                ))}
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Typography variant="display6" paragraph>
                Delivery Info :
              </Typography>
              <Grid container spacing={2}>
                {deliveryInfo?.map((item, index) => (
                  <React.Fragment key={index}>
                    {item?.value ? (
                      <Grid item xs={12} sm={6} md={4} className="ellipsis">
                        <Typography>{item?.title}</Typography>
                        <Typography variant="subtitle">
                          {item?.value}
                        </Typography>
                      </Grid>
                    ) : null}
                  </React.Fragment>
                ))}
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Typography variant="display6" paragraph>
                Billing Info:
              </Typography>
              <Grid container spacing={2}>
                {billingInfo?.map((item, index) => (
                  <React.Fragment key={index}>
                    {item?.value ? (
                      <Grid item xs={12} sm={6} md={4} className="ellipsis">
                        <Typography>{item?.title}</Typography>
                        <Typography variant="subtitle">
                          {item?.value}
                        </Typography>
                      </Grid>
                    ) : null}
                  </React.Fragment>
                ))}
              </Grid>
            </TabPanel>
            {data?.orderItems?.map((item, index) => {
              return (
                <TabPanel key={item + index} value={value} index={index + 1}>
                  {Object?.keys(itemDetailObj)?.map(
                    (sectionItem, sectionIndex) => {
                      return itemDetailObj[sectionItem]?.(item)?.length ? (
                        sectionItem === 'Reveals Info' && // Hide reveals section when reveals get false for specific cabinet
                        !item?.reveals ? null : (
                          <React.Fragment key={sectionItem}>
                            <Typography variant="display6" paragraph>
                              {sectionItem}:
                            </Typography>
                            <Grid container spacing={2}>
                              {itemDetailObj[sectionItem]?.(item)?.map(
                                (item, index) => (
                                  <React.Fragment key={index}>
                                    {item?.value ? (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        // className="ellipsis"
                                        width="30%"
                                      >
                                        <Typography>{item?.title}</Typography>
                                        <Typography
                                          // noWrap
                                          variant="subtitle"
                                          sx={{
                                            textWrap: 'wrap',
                                          }}
                                        >
                                          {item?.value}
                                        </Typography>
                                      </Grid>
                                    ) : null}
                                  </React.Fragment>
                                )
                              )}
                            </Grid>
                            {sectionIndex <
                            Object?.keys(itemDetailObj)?.length - 1 ? (
                              <Divider sx={{ my: 2 }} />
                            ) : null}
                          </React.Fragment>
                        )
                      ) : null;
                    }
                  )}
                </TabPanel>
              );
            })}
          </>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

OrderItemDialog.propTypes = {
  open: PropTypes?.bool,
  selectedItem: PropTypes?.object,
  handleClose: PropTypes?.func,
  projectType: PropTypes?.string,
};

export default OrderItemDialog;
