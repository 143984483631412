import React from 'react';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';

import { useInternetState } from 'utils/useInternetState';

export default function AlertSx({
  showAlert,
  handleClose,
  severity,
  message,
  position,
  sx,
  autoHideDuration,
}) {
  const isOnline = useInternetState();
  return (
    <Snackbar
      autoHideDuration={autoHideDuration || 3000}
      anchorOrigin={position || { vertical: 'top', horizontal: 'right' }}
      sx={sx || {}}
      open={showAlert}
      onClose={handleClose}
      key={'top right'}
      severity={severity}
    >
      <Alert severity={severity} sx={{ minWidth: '350px' }}>
        {!isOnline
          ? 'No internet connection'
          : message || 'Something went wrong'}
      </Alert>
    </Snackbar>
  );
}

AlertSx.propTypes = {
  showAlert: PropTypes.bool,
  handleClose: PropTypes.func,
  severity: PropTypes.string,
  message: PropTypes.string,
  position: PropTypes.object,
  sx: PropTypes.object,
  autoHideDuration: PropTypes.number,
};
